import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/(standalone)/admin": [~22,[2,5]],
		"/(app)/(business)/calendar/[[date]]": [~8,[2,3]],
		"/(app)/(business)/dashboard": [~9,[2,3]],
		"/(app)/(business)/establishment-setup": [~10,[2,3]],
		"/(public)/forgotten-password": [26,[6]],
		"/(public)/join/[[businessKey]]/[[joinHash]]/[[emailHash]]": [~27,[6]],
		"/(public)/login": [~28,[6]],
		"/(public)/logout": [~29,[6]],
		"/(app)/(standalone)/new-establishment": [23,[2,5]],
		"/(app)/(business)/notifications": [11,[2,3]],
		"/(app)/(business)/onboarding": [~12,[2,3]],
		"/(app)/(business)/pending-join-request": [~13,[2,3]],
		"/(app)/(standalone)/profile": [~24,[2,5]],
		"/(public)/register": [~30,[6]],
		"/(public)/reset-password/[token]": [~31,[6]],
		"/(app)/(business)/settings": [14,[2,3,4]],
		"/(app)/(business)/settings/general": [15,[2,3,4]],
		"/(app)/(business)/settings/notifications": [16,[2,3,4]],
		"/(app)/(business)/settings/plans": [17,[2,3,4]],
		"/(app)/(business)/settings/tags": [18,[2,3,4]],
		"/(app)/(business)/settings/templates": [~19,[2,3,4]],
		"/(app)/(business)/settings/worker-profile": [~20,[2,3,4]],
		"/(app)/(business)/team": [~21,[2,3]],
		"/(app)/unverified": [~25,[2]],
		"/(public)/verify/[hash]": [~32,[6]],
		"/[...path]": [~33]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';